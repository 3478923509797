<template>
  <div class="page">
    <div class="sidebar">

      <div class="logo">
        <img width="220px"
             src="@/assets/gis/caso.png"
             alt="" />
      </div>

      <div class="list">
        <router-link to="/personal"
                     :class="{ active: isActive('/personal') }">
          <div class="real">
            <img src="@/assets/svg/gis-home.svg"
                 alt="">
            <span>Home</span>
          </div>
        </router-link>

        <router-link to="/map"
                     :class="{ active: isActive('/map') }">
          <div class="real">
            <img src="@/assets/svg/gis-map.svg"
                 alt="">
            <span>map</span>
          </div>
        </router-link>

        <router-link to="/detail"
                     :class="{ active: isActive('/detail') }">
          <div class="real">
            <img src="@/assets/svg/gis-sql.svg"
                 alt="">
            <span>数据库</span>
          </div>
        </router-link>

        <router-link :to="currentUserName == null ? goinurl.yes:goinurl.no">
          <div class="real">
            <el-col :span="12">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <img src="@/assets/svg/gis-logon.svg"
                       alt="">
                  {{ currentUserName === null ? '登录': currentUserName}}
                </span>
                <el-dropdown-menu slot="dropdown"
                                  :class="{'no_goin':currentUserName == null}">
                  <el-dropdown-item icon="el-icon-message-solid"> <router-link to="/gislist"
                                 style="color: #666;">气象站管理</router-link></el-dropdown-item>
                  <el-dropdown-item icon="el-icon-user-solid"> <router-link to="/gisuser"
                                 style="color: #666;">用户信息</router-link></el-dropdown-item>
                  <el-dropdown-item icon="el-icon-close"> <i @click="logout"
                       style="color: #666;">退出登录</i></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-col>
          </div>
        </router-link>
      </div>
    </div>

    <div class="content-container">
      <router-view></router-view>
    </div>

  </div>
</template>
<script>
import $cookies from "vue-cookies";
import { $user} from "@/services";
export default {
  name: "GISHome",
  data () {
    let charvb = {
      xAxis: {
        data: ['12:00', '13:00', '14:00', '15:00', '16:00',
          '17:00'
        ]
      },
      grid: {
        top: "10%",
        bottom: "15%"
      },
      yAxis: {},
      series: [
        {
          smooth: true,
          type: 'line',
          color: '#00479D',
          data: [5, 10, 15, 12, 8, 6],
        }
      ]
    }
    return {
      goinurl: {
        yes: '/login',
        no: '/gisuser'
      },
      charvb,
      visible: false,
      currentUserName: "",
      no_goin: true,
      Authorization: '',
    };
  },
  created () {
      this.currentUserName = $cookies.get("currentUserName");
      this.Authorization = $cookies.get("Authorization");
      this.crea()
  },
  methods: {
    logout () {
      $user.logout().then((res) => {
        if (res.code == 0) {
          this.currentUserName = null;
          $cookies.remove("Authorization");
          $cookies.remove("currentUserName");
          this.goHome()
        }
      })
    },

    isActive (route) {
      return this.$route.path === route;
    },
    crea () {
      if (this.currentUserName === null) {
        this.visible = false
      } else {
        this.visible = true
      }
    },
    goHome () {
      this.$router.push("/map");
    },
    goLogin () {
      this.$router.push({ name: 'login', query: { redirect: this.$route.fullPath } });
    },
  },
};
</script>
<style lang="less" scoped>
.active {
  background-color: rgb(0, 106, 145);
}
.no_goin {
  display: none;
}
.page {
  // font-family: Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-family: '微软雅黑';
  position: relative;
  .sidebar {
    background-color: #08b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 100px;

    .list {
      display: flex;
      height: 50px;
      a {
        color: #fff;
        display: flex;
        align-items: center;
        padding: 0 15px;

        img {
          width: 16px;
          height: 16px;
          margin-right: 3px;
          vertical-align: bottom;
        }
      }
    }
  }
}

.mainlist {
  width: 408px;
  font-size: 14px;
  color: #fff;
  box-sizing: border-box;
  border-radius: 0 5px 5px 0;
  overflow-y: auto;
  height: calc(100vh - 50px);
  background-color: #fff;
  position: absolute;
  width: 400px;
  top: 50px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  left: -100%;
  // display: none;
  transition: left 0.5s;
  box-shadow: 2px 2px 5px 2px #9999996c;
  .box1 {
    flex-shrink: 0;
    width: 100%;
    height: 240px;
    background-image: url('../../assets/svg/gissss.png');
    background-size: 100% 100%;
    display: flex;
    align-items: flex-end;
    padding: 50px 20px 6px;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 50%);
    h1 {
      font-size: 28px;
      margin-bottom: 12px;
    }
    h2 {
      font-size: 18px;
      margin-bottom: 10px;
    }
    p {
      font-size: 85%;
      margin: 2px 0;
    }

    img {
      height: 50px;
      vertical-align: baseline;
      margin-right: 10px;
    }
  }

  .box2 {
    width: 100%;
    // background-color: #666;
    display: flex;
    flex-direction: column;
    color: #666;
    justify-content: space-between;
    padding: 10px 20px;
    .list {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      border: 1px solid #666;
      border-radius: 5px;
      .left {
        display: flex;
        align-content: center;
        justify-content: center;
        padding-left: 10px;
        img {
          width: 50px;
        }
        .MeteorologicalElements {
          line-height: 50px;
          font-size: 14px;
        }
      }
      .right {
        padding-right: 10px;
        span {
          margin-left: 5px;
        }
      }
    }
  }

  .box3 {
    flex: 1;
    display: flex;
    align-items: center;
    p {
      width: 100%;
      padding: 0 20px;
      text-align: center;
      a {
        background-color: #08b;
        border-radius: 5px;
        display: block;
        width: 100%;
        font-size: 14px;
        padding: 11px 0;
      }
    }
  }

  .box4 {
    width: 100%;
    height: 170px;
    flex-shrink: 0;
    background: linear-gradient(#491cc2af, #491cc2);
    .top {
      display: flex;
      flex-direction: column;
      .wendu {
        display: flex;
        justify-content: center;
        p {
          margin-top: 40px;
          font-size: 56px;
          display: flex;
          span {
            font-size: 30px;
          }
        }
      }
    }
    .cer {
      display: flex;
      justify-content: center;
      p {
        margin: 10px 10px;
      }
    }
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
  display: flex;
  width: 70px;
  font-size: 16px;
}
.el-icon-arrow-down {
  font-size: 30px;
}
</style>